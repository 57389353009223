/*=====================
  3.3 Footer Css 
==========================*/
.footer-wrap {
  margin: 0 auto;
  max-width: 767px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
  background-color: $theme-color;
  z-index: 3;
  @media only screen and (min-width: 767px) {
    max-width: 500px;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 8px;
    .footer-item {
      .footer-link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2px;
        position: relative;

        i {
          font-size: 25px;
          color: $white;
        }
        .icon {
          width: calc(30px + (32 - 30) * ((100vw - 320px) / (1920 - 320)));
          height: calc(30px + (32 - 30) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: -7px;
        }
        .offer {
          margin-top: 2px;
        }
        span {
          color: $white;
          margin-bottom: -3px;
          font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          display: block;
          background-color: $white;
          border-radius: 100%;
          top: -8px;
          opacity: 0;
          transition: all 0.4s;
        }
      }

      &.active,
      &:hover {
        .footer-link {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  &.shop {
    padding-left: 15px;
    padding-right: 15px;
    max-width: calc(100vw - 30px);
    border-radius: 4px;
    bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) 10px;
    @media only screen and (min-width: 767px) {
      max-width: calc(600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))));
    }
    .footer {
      justify-content: space-between;
      margin-top: 0;
      .footer-item {
        opacity: 1;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        > span {
          color: $white;
          &:last-of-type {
            font-weight: 600;
          }
        }
        > a {
          font-weight: 600;
          color: $white;
          line-height: 21px;
          margin-right: -7px;
          display: flex;
          align-items: center;
          gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
          @media only screen and (max-width: 400px) {
            font-size: 14px !important;
          }
          [dir="rtl"] & {
            margin-left: -7px;
            margin-right: unset;
          }
          svg {
            margin-bottom: -2.2px;
            width: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
            height: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
            [dir="rtl"] & {
              transform-origin: center;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  &.footer-button {
    padding-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    max-width: calc(100vw - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))));
    border-radius: 4px;
    text-align: center;
    bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    @media only screen and (min-width: 767px) {
      max-width: calc(600px - calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))));
    }

    a {
      width: 100%;
      display: block;
      color: $white;
      font-weight: 600;
    }
  }
}
