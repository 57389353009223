// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$forrest-primary: mat.define-palette(mat.$indigo-palette);
$forrest-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$forrest-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$forrest-theme: mat.define-light-theme((color: (primary: $forrest-primary,
        accent: $forrest-accent,
        warn: $forrest-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($forrest-theme);


/* You can add global styles to this file, and also import other style files */

@import "./assets/fonts/avenir/avernir.scss";

@import "./assets/scss/vendors/bootstrap.scss";
// @import '~bootstrap/dist/css/bootstrap.min.css';

@import "./assets/css/iconly.css";

@import "./assets/icons/fontawesome/css/all.min.css";

@import "./assets/scss/vendors/slick.scss";

@import "./assets/scss/vendors/slick-theme.scss";

@import "./assets/scss/style.scss";

html {
  background-color: #eeeeee;
}

html,
body {
  min-height: 100vh;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  touch-action: pan-x pan-y;
  -webkit-overflow-scrolling: touch;
  user-select: none;
}

.height-full {
  min-height: calc((100vh - 60px)) !important;
}

.main-wrap {
  min-height: calc((100vh - 60px)) !important;
  padding: 0px;
  // >* {
  //   padding: 0 15px;
  // }

  &.mb-xxl {
    margin-bottom: calc(60px + 25 * (100vw - 320px) / 1600)
  }
}



.custom-form input:focus,
.custom-form textarea:focus,
.custom-form select:focus,
.custom-form .form-control:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

// .mdc-text-field__input {
//   height: 50px !important;
// }
.container-list {
  padding-left: $padding-element-default;
  padding-right: $padding-element-default;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}


.mat-mdc-snack-bar-container {
  margin: auto !important;
  position: absolute !important;
  max-width: 375px !important;
  width: 90% !important;
  bottom: 100px !important;
  border-radius: 10px;
  overflow: hidden;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label,
.mat-mdc-snack-bar-container,
input,
.mat-mdc-dialog-container .mdc-dialog__title {
  font-family: $font-1 !important;
}

input {
  font-weight: 300 !important;
}

.mdc-snackbar__label,
.mdc-snackbar__surface {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-snack-bar-container,
.mdc-snackbar__surface {
  background-color: transparent !important;
  box-shadow: none !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--primary, #3f51b5) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgb(224 224 224) !important;
}

forrest-checkout,
forrest-input-text,
forrest-input-email,
forrest-input-phone-prefix,
forrest-input-password {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  mat-radio-button {
    width: 100% !important;

    .mdc-form-field {
      display: flex !important;

      label {
        width: 100% !important;
      }
    }
  }
}

.input-prefix {
  max-width: 55px;

  .mdc-text-field {
    padding-right: 0px;
  }
}


.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: $black !important;
  background-color: $black !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: $black !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: $black !important;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: $black !important;
  --mdc-radio-selected-hover-icon-color: $black !important;
  --mdc-radio-selected-icon-color: $black !important;
  --mdc-radio-selected-pressed-icon-color: $black !important;
  --mat-mdc-radio-checked-ripple-color: $black !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: $black !important;
  --mdc-checkbox-selected-hover-icon-color: $black !important;
  --mdc-checkbox-selected-icon-color: $black !important;
  --mdc-checkbox-selected-pressed-icon-color: $black !important;
}

.mdc-checkbox__background {
  border-radius: 4px !important;
}



.mat-mdc-radio-button {
  .mdc-radio {
    padding: 0 !important;
  }
}


.bluer {
  >* {
    filter: blur(2px);
  }
}

.background-blur {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.bg-primary {
  background-color: $primary !important;
}

.bg-light {
  background-color: $gray-100 !important;
}

.text-primary {
  color: $primary !important;
}

.btn-size-m {
  width: 35px !important;
  height: 35px !important;

  .mat-mdc-button-touch-target {
    width: 35px !important;
    height: 35px !important;
  }

}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  box-shadow: none !important;
}

.btn-icon-svg {
  display: inline-block;
  padding: 0px;
  background-color: transparent;
  border: none;
  border-radius: 4px;

  .svg-icon {
    svg {
      height: $font-size-1;
      width: $font-size-1;
    }
  }
}

.btn-icon-round-svg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: #e0e0e0;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;

  .svg-icon {
    svg {
      height: $font-size-1;
      width: $font-size-1;
    }
  }
}

.tool {
  display: flex;
  align-items: end;
  gap: 10px;
}

.bg-bottom-rouder {
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/** CARD card-form-modifiers**/
.card-form-modifiers {
  .card-body {
    padding-top: $font-size-6;
    padding-bottom: $font-size-5;
    padding-left: $font-size-5;
    padding-right: $font-size-5;

    .card-body-header {
      .card-title {
        font-size: $font-size-3;
        margin: 0px;
      }

      .card-text {
        font-size: $font-size-5;
        color: $color-grey;
      }

      .badge {
        background-color: $gray-200;
        padding: 5px 12px;
        font-weight: 600;
      }
    }

    form {

      p {
        font-size: $font-size-4;
        line-height: $font-size-4 ;
      }

      span {
        font-size: $font-size-5;
        color: $color-grey;
        line-height: $font-size-4 ;
      }

      .mat-mdc-radio-touch-target,
      .mat-mdc-checkbox-touch-target {
        width: 10px !important;
        height: 10px !important;
      }
    }
  }
}


/* Definir la animación */
@keyframes enterAnimation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  50% {
    opacity: .5;
    transform: scale(1);
  }

  90% {
    opacity: .9;
    transform: none;
  }

  // 100% {
  //   opacity: 1;
  //   transform: none;
  // }
}

/* Aplicar la animación a los elementos deseados */
.enter-animation {
  animation-name: enterAnimation;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-fill-mode: both;
  transform-origin: top left;
}

.form-input {
  border: 1px solid $gray-200;
  height: calc(32px + (34 - 32) * ((100vw - 320px) / (1920 - 320))) !important;
  ;
  font-weight: 500 !important;
  border-radius: $border-radius-default;
  // resize: none;
  padding: $font-size-6;
  width: 100%;
  font-size: $font-size-4 ;
  font-family: $font-2;
  color: $color-grey;
  transition: border-color 0.3s;

  &::placeholder {
    font-weight: 400;
  }

  &:focus {
    background-color: $gray-100;
    // border-bottom: 2px solid;
    border-color: $primary;
  }

  &:disabled {
    border-color: $gray-100;
    background-color: $gray-100;

    &::placeholder {
      // Estilos para el placeholder cuando el input está deshabilitado

      color: $gray-200;
    }
  }



}

button.form-input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 2px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 70px;
}



.mat-bottom-sheet-container-xlarge,
.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium,
.mat-bottom-sheet-container {
  border-top-left-radius: $border-radius-default !important;
  border-top-right-radius: $border-radius-default !important;
  padding: 8px 0px !important;
}

.element-list-icon {
  display: flex;
  align-items: center;

  .svg-icon {
    margin-right: $font-size-5;

    svg {
      width: 26px;
      height: 16px;
    }
  }
}

.modal-surverys,
.modal-inapp {
  ::-webkit-scrollbar {
    display: none;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
  }


}

// sombra azul inApp
// forrest-element-modal-surveys, forrest-element-inapp{
//   .cdk-overlay-dark-backdrop{
//     background-color: #082A4180 !important;
//   }
// }



.mat-bottom-sheet-container-height-full {
  .mat-bottom-sheet-container {
    transition: 1s;
    height: 99vh;
    max-height: calc(99vh - env(safe-area-inset-top))
  }
}

.mat-bottom-sheet-upselling-products {
  .mat-bottom-sheet-container {
    background-color: $white-light !important;
    overflow-y: hidden !important;
  }
}

.mat-bottom-sheet-container-height-90 {
  .mat-bottom-sheet-container {
    height: 90vh;
    max-height: 90vh
  }

}

.bg-container-address{
  .mat-bottom-sheet-container{
    background-color: $white-light;
    padding-top: 0px !important;
  }
}


// forrest-modal-onboarding {
//   .carousel-inner {
//     height: 100% !important;
//   }
// }

.dialog-help {
  width: 95vw;
  height: 95vh;
  max-width: 520px !important;

  #some-id {
    width: 100%;
    height: 100%;
  }

  #hubspot-conversations-inline-iframe {
    width: 100%;
    height: 94vh;
    border: none;
  }
}


.skeleton-box {
  display: inline-block;
  height: 2em;
  width: 60px;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  border-radius: 5px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0));
    animation: shimmer 5s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.pointer{
  cursor: pointer
}

.gap-default{
  gap: 10px
}

.input-danger {
  border-color: $color-warning !important;
}

.general-text-error {
  color: $color-danger;
  font-size: $font-size-5;
  margin-bottom: 0px;
}

.d-none {
  display: none;
}

.d-block {
  display: block !important;
}

.container-error {
  flex-direction: column !important;
  margin-bottom: 0.5em !important;
}