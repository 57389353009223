// Fonts///
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  src: url('./Avenir-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Light.ttf') format('truetype');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Medium.ttf') format('truetype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Medium.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Heavy.ttf') format('truetype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Heavy.ttf') format('truetype');
    font-weight: 800;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('./Avenir-Black.ttf') format('truetype');
    font-weight: 900;
  }