//
// SVG Icon
//

$font-size-base: 1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg: $font-size-base * 1.075 !default; // 14.04px
$font-size-sm: $font-size-base * 0.925 !default; // 12.025px
// Font Sizes
$font-sizes: (
  1: $h1-font-size,
  // 22.75px
  2: $h2-font-size,
  // 19.50px
  3: $h3-font-size,
  // 17.55px
  4: $h4-font-size,
  // 16.25px
  5: $h5-font-size,
  // 14.95px
  6: $h6-font-size,
  // 13.95px
  7: $font-size-base * 0.95,
  // 12.35px
  8: $font-size-base * 0.85,
  // 11.05px
  9: $font-size-base * 0.75,
  // 9.75px
  10: $font-size-base * 0.5,
  // 6.50px
  base: $font-size-base,
  // 13px
  fluid: 100%,
  // 100%
  2x: $font-size-base * 2,
  // 26px
  2qx: $font-size-base * 2.25,
  // 29.25px
  2hx: $font-size-base * 2.5,
  // 32.5px
  2tx: $font-size-base * 2.75,
  // 35.75px
  3x: $font-size-base * 3,
  // 39px
  3qx: $font-size-base * 3.25,
  // 42.25px
  3hx: $font-size-base * 3.5,
  // 45.5px
  3tx: $font-size-base * 3.75,
  // 48.75px
  4x: $font-size-base * 4,
  // 52px
  4qx: $font-size-base * 4.25,
  // 55.25px
  4hx: $font-size-base * 4.5,
  // 58.5px
  4tx: $font-size-base * 4.75,
  // 61.75px
  5x: $font-size-base * 5,
  // 65px
  5qx: $font-size-base * 5.25,
  // 68.25px
  5hx: $font-size-base * 5.5,
  // 71.5px
  5tx: $font-size-base * 5.75 // 74.75px,
) !default;


$theme-text-colors: (
  "white": $white,
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "muted": $text-muted,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
) !default;


@mixin svg-icon-color($color, $important: false) {
  svg {
      [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: $color valueif($important, !important, null) !important;
      }

      &:hover {
          [fill]:not(.permanent):not(g) {
              transition: fill 0.3s ease;
          }
      }
  }
}

@mixin svg-icon-transition() {
  svg {
      [fill]:not(g) {
          transition: fill 0.3s ease;
      }
  }
}

@mixin svg-icon-size($size, $important: false) {
  svg {
      height: $size valueif($important, !important, null);
      width: $size valueif($important, !important, null);
  }
}


.svg-icon {
  line-height: 1;

  @include svg-icon-size(get($font-sizes, 5));
  @include svg-icon-color($text-muted);

  // Theme colors
  @each $name, $color in $theme-text-colors {
      &.svg-icon-#{$name} {
          @include svg-icon-color($color);
      }
  }

  // Responsive icon sizes
  @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
          $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

          // Sizes
          @each $name, $value in $font-sizes {
              &.svg-icon#{$infix}-#{$name} {
                  @include svg-icon-size($value, true);
              }
          }
      }
  }
}
