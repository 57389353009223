/*=====================
   1.2 Typography CSS
==========================*/
/// Avenir Font Start ///

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  src: url('../../fonts/avenir/Avenir-Light.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Light.ttf') format('truetype');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Medium.ttf') format('truetype');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Medium.ttf') format('truetype');
    font-weight: 500;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Medium.ttf') format('truetype');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Heavy.ttf') format('truetype');
    font-weight: 700;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Heavy.ttf') format('truetype');
    font-weight: 800;
  }
  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    src: url('../../fonts/avenir/Avenir-Black.ttf') format('truetype');
    font-weight: 900;
  }
/// Quicksand Font End ///

body {
  font-family: $font-1;
}
ul {
  list-style: none;
}
li {
  font-size: 14px;
  color: $title-color;
}

p {
  font-size: 14px;
  line-height: 1.5;
  color: $title-color;
}

a {
  transition: 0.5s ease;
  text-decoration: none;
  color: $title-color;

  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
    color: currentColor;
  }
  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.form-control {
  font-size: 14px;
  color: $title-color;
  [dir="rtl"] & {
    text-align: right;
  }
  &:focus {
    box-shadow: none;
  }
}

h1 {
  font-size: 38px;
  line-height: 33px;
  text-transform: capitalize;
  margin: 0;
  color: $title-color;
}

h2 {
  font-size: 24px;
  line-height: 22px;
  margin: 0;
  color: $title-color;
}

h3 {
  font-size: 20px;
  line-height: 16px;
  margin: 0;
  color: $title-color;
}

h4 {
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  color: $title-color;
}

h5 {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: 400;
  color: $title-color;
}

h6 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin: 0;
  color: $title-color;
}

.font-base {
  font-size: 14px !important;
}

/// Font Size ///
$theme-font-size: (
  xs: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320))),
  sm: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))),
  md: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))),
  lg: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))),
  xl: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320))),
  xxl: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320))),
  default: 16px,
);
@each $size, $value in $theme-font-size {
  .font-#{$size} {
    font-size: $value !important;
  }
}
.title-2 {
  font-weight: 600;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}
.title-color {
  color: $title-color;
}
.font-white {
  color: $white;
}
.content-color {
  color: $content-color;
}
.font-theme {
  color: $theme-color !important;
}
.font-danger {
  color: $theme-danger !important;
}
